import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import FaqData from "../../data/faq/FaqData.json";
import SectionTitle from "../../elements/section-title/SectionTitle";

const allData = FaqData;

const FaqOne = () => {
  return (
    <div className='section section-padding-equal bg-color-light faq-area'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5 col-xl-4'>
            <SectionTitle
              subtitle='Pricing Plan'
              title='Your Questions, Answered'
              description='Below you’ll find answers to the most common questions about our website and digital marketing packages. If you have other questions, feel free to reach out!'
              textAlignment='heading-left'
              textColor=''
            />
          </div>
          <div className='col-lg-7 col-xl-8'>
            <div className='faq-accordion'>
              <Accordion defaultActiveKey='1'>
                {allData.map((data) => (
                  <Accordion.Item eventKey={data.id} key={data.id}>
                    <Accordion.Header>
                      <span>{`${data.id}.`}</span>
                      {data.title}
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>{data.body}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <ul className='shape-group-6 list-unstyled'>
        <li className='shape shape-1'>
          <img src={"/images/others/bubble-7.png"} alt='Bubble' />
        </li>
        <li className='shape shape-2'>
          <img src={"/images/others/line-4.png"} alt='line' />
        </li>
        <li className='shape shape-4'>
          <img src={"/images/others/poses-lady.png"} alt='Poses' />
        </li>
      </ul>
    </div>
  );
};

export default FaqOne;