import React from 'react';
import { Link } from "react-router-dom";

const PropOne = ({ projectStyle, portfolio }) => {
  return (
    <>
      <div className={`project-grid ${projectStyle}`}>
        <div className='thumbnail'>
          <Link to='/contact'>
            {/* <img src={portfolio.image} alt='icon' /> */}
          </Link>
        </div>
        <div className='content'>
          <span className='subtitle'>
            <span>{portfolio.excerpt}</span>
          </span>
          <h3 className='title'>
            <Link to='/contact'>{portfolio.title}</Link>
          </h3>
        </div>
      </div>
    </>
  );
};

export default PropOne;