import React from 'react';
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectOne from "../component/project/ProjectsPageProjectOne";
import BcrumbBannerTwo from "../elements/breadcrumb/BcrumbBannerTwo";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

const ProjectGridTwo = () => {
  return (
    <>
      <SEO
        title='Axellier | Projects - AI, Marketing, and Digital Solutions'
        description="Discover Axellier's portfolio of innovative projects, ranging from AI-powered analytics to custom website development and digital marketing solutions. See how we solve industry-specific challenges with intelligent design and cutting-edge technology."
        keywords='Axellier projects, AI-powered analytics, custom website development, digital marketing solutions, mobile app development, SEO campaigns, tailored digital solutions, business growth, AI-driven solutions, cloud healthcare, IoT agriculture'
      />

      <ColorSwitcher />
      <main className='main-wrapper'>
        <HeaderOne />
        <BcrumbBannerTwo
          title='Our Projects'
          paragraph='A quick view of industry specific problems solved with design by the awesome team at Axellier.
                '
          styleClass=''
          mainThumb='/images/banner/banner-thumb-1.gif'
        />
        <ProjectOne colSize='col-xl-4 col-md-6' columnGap='row-15' />
        <CtaLayoutOne />
        <FooterOne parentClass='' />
      </main>
    </>
  );
};

export default ProjectGridTwo;