import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";

// Home Pages Import
import DigitalAgency from "./pages/DigitalAgency";

// Blog Import
import BlogArchive from "./pages/Archive";
import BlogDetails from "./pages/BlogDetails";
import BlogGridView from "./pages/BlogGrid";
import BlogCategory from "./pages/Category";

// Service
import ServiceDetails from "./pages/ServiceDetails";
import ServiceOne from "./pages/ServiceOne";
import ServiceTwo from "./pages/ServiceTwo";

// Project
import ProjectDetails from "./pages/ProjectDetails";
import ProjectGridTwo from "./pages/ProjectGridTwo";

// Pages
import ErrorPage from "./pages/404";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import PricingTable from "./pages/PricingTable";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

// Css Import
import "./assets/scss/app.scss";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={"/"} element={<DigitalAgency />} />

          {/* <Route path={"/digital-agency"} element={<DigitalAgency />}/> */}
          {/* <Route path={"/creative-agency"} element={<CreativeAgency />}/> */}
          {/* <Route path={"/personal-portfolio"} element={<PersonalPortfolio />}/> */}
          {/* <Route path={"/home-startup"} element={<HomeStartup />}/> */}
          {/* <Route path={"/corporate-agency"} element={<CorporateAgency />}/> */}

          {/* Blogs */}
          <Route path={"/blog-grid/"} element={<BlogGridView />} />
          <Route path={"/category/:slug"} element={<BlogCategory />} />
          <Route path={"/archive/:slug"} element={<BlogArchive />} />
          <Route path={"/blog-details/:id"} element={<BlogDetails />} />

          {/* Service */}
          <Route path={"/service-one/"} element={<ServiceOne />} />
          <Route path={"/service-two/"} element={<ServiceTwo />} />
          <Route path={"/service-details/:slug"} element={<ServiceDetails />} />

          {/* Project  */}
          {/* <Route path={"/project-grid-one"} element={<ProjectGridOne />} /> */}
          <Route path={"/projects"} element={<ProjectGridTwo />} />
          {/* <Route path={"/project-grid-three"} element={<ProjectGridThree />} /> */}
          {/* <Route path={"/project-grid-four"} element={<ProjectGridFour />} /> */}
          {/* <Route path={"/project-grid-five"} element={<ProjectGridFive />} /> */}
          <Route path={"/project-details/:slug"} element={<ProjectDetails />} />
          <Route
            path={"/project-details/:slug/*"}
            element={<ProjectDetails />}
          />

          {/* Pages  */}
          <Route path={"/about-us"} element={<AboutUs />} />
          {/* <Route path={"/our-office"} element={<OurOffice />} /> */}
          {/* <Route path={"/our-clients"} element={<OurClients />} /> */}
          {/* <Route path={"/team"} element={<Team />} /> */}
          {/* <Route path={"/team-details/:slug"} element={<TeamDetails />} /> */}
          {/* <Route path={"/case-study"} element={<CaseStudy />} /> */}
          {/* <Route path={"/case-details/:slug"} element={<CaseDetails />} /> */}
          {/* <Route path={"/testimonials"} element={<Testimonials />} /> */}
          <Route path={"/promotions"} element={<PricingTable />} />
          {/* <Route path={"/typography"} element={<Typography />} /> */}
          <Route path={"/contact"} element={<Contact />} />
          <Route path={"/404"} element={<ErrorPage />} />
          {/* <Route path={"/coming-soon"} element={<ComingSoon />} /> */}
          <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={"/terms-use"} element={<TermsOfUse />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
