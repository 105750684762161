import React from "react";
import { FaAngleDown, FaAngleRight, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { featureFlags } from "../../utils/config";

const isMobile = window.innerWidth <= 820;

function ServiceMainMenuItem({ title }) {
  return (
    <span
      style={{
        fontWeight: "bold",
        padding: "7px 15px",
        display: "inline-block",
        cursor: "arrow",
      }}
    >
      {title} <FaAngleRight />
    </span>
  );
}

const ServicesDropdown = () => {
  const gridStyles = !isMobile
    ? {
        display: "grid",
        gridTemplateColumns: "repeat(3, 350px)",
        // gridTemplateRows: 'repeat(2, 1fr)',
        gap: "16px",
        minWidth: "1050px",
        left: "50%",
        transform: "translateX(-50%)",
      }
    : {};

  return (
    <ul className='axil-submenu' style={gridStyles}>
      {/* {featureFlags.WebCraft && <li className='menu-item-has-children'>
    <ServiceMainMenuItem title="WebCraft 🌐" />
    <ul className='axil-submenu-inner'>
        <li><Link to={"/service-details/webcraft/"}>Custom Website Development</Link></li>
        <li><Link to={"/service-details/webcraft/"}>Web Hosting & Domain Services</Link></li>
        <li><Link to={"/service-details/webcraft/"}>Detailed Web Accessibility Audits</Link></li>
        <li><Link to={"/service-details/webcraft/"}>Advanced Digital Security Solutions</Link></li>
    </ul>
</li>} */}
      {featureFlags.SoftForge && (
        <li className='menu-item-has-children'>
          <ServiceMainMenuItem title='SoftForge 💻' />
          <ul className='axil-submenu-inner'>
            <li>
              <Link to={"/service-details/softforge/"}>
                Intelligent AI-Powered Solutions
              </Link>
            </li>
            <li>
              <Link to={"/service-details/softforge/"}>
                Custom Website Development
              </Link>
            </li>
            <li>
              <Link to={"/service-details/softforge/"}>
                Tailored Mobile App Development
              </Link>
            </li>
            <li>
              <Link to={"/service-details/softforge/"}>
                Advanced Digital Security Audits
              </Link>
            </li>
          </ul>
        </li>
      )}
      {/* {featureFlags.AfterCare && <li className='menu-item-has-children'>
    <ServiceMainMenuItem title="AfterCare 🛠️" />
    <ul className='axil-submenu-inner'>
        <li><Link to={"/service-details/aftercare/"}>Professional Training & Onboarding</Link></li>
        <li><Link to={"/service-details/aftercare/"}>Performance Monitoring & Feedback</Link></li>
        <li><Link to={"/service-details/aftercare/"}>Systematic Version Control & Updates</Link></li>
        <li><Link to={"/service-details/aftercare/"}>Lifetime Support & Enhancements</Link></li>
    </ul>
</li>} */}
      {/* {featureFlags.DesignScape && <li className='menu-item-has-children'>
    <ServiceMainMenuItem title="DesignScape 🎨" />
    <ul className='axil-submenu-inner'>
        <li><Link to={"/service-details/designscape/"}>Strategic Brand Visualization</Link></li>
        <li><Link to={"/service-details/designscape/"}>Custom Illustration Services</Link></li>
        <li><Link to={"/service-details/designscape/"}>Dynamic Motion Graphics & Animation</Link></li>
        <li><Link to={"/service-details/designscape/"}>User Experience & Prototyping</Link></li>
    </ul>
</li>} */}

      {featureFlags.Marketech && (
        <li className='menu-item-has-children'>
          <ServiceMainMenuItem title='Marketech 🚀' />
          <ul className='axil-submenu-inner'>
            <li>
              <Link to={"/service-details/marketech/"}>
                Social Media Strategy & Advertisements
              </Link>
            </li>
            <li>
              <Link to={"/service-details/marketech/"}>
                SEO & Retargeting Campaigns
              </Link>
            </li>
            <li>
              <Link to={"/service-details/marketech/"}>
                Email & Video Marketing
              </Link>
            </li>
            <li>
              <Link to={"/service-details/marketech/"}>
                Influencer Partnerships
              </Link>
            </li>
          </ul>
        </li>
      )}
      {featureFlags.Insightify && (
        <li className='menu-item-has-children'>
          <ServiceMainMenuItem title='Insightify 🔍' />
          <ul className='axil-submenu-inner'>
            <li>
              <Link to={"/service-details/insightify/"}>
                In-depth Market Research & Analysis
              </Link>
            </li>
            <li>
              <Link to={"/service-details/insightify/"}>
                Sentiment & Competitor Analysis
              </Link>
            </li>
            <li>
              <Link to={"/service-details/insightify/"}>
                Conversion Rate Optimization Strategies
              </Link>
            </li>
            <li>
              <Link to={"/service-details/insightify/"}>
                Customer Journey Mapping & Analysis
              </Link>
            </li>
          </ul>
        </li>
      )}
    </ul>
  );
};

const Nav = () => {
  const promotionItemStyle = {
    position: "relative",
    display: "inline-block",
    margin: "0 15px",
  };

  const promotionBadgeStyle = !isMobile
    ? {
        position: "absolute",
        top: "6px", // Move it down a bit
        right: "-12px", // Bring it closer to the "Promotions" word
        backgroundColor: "gold",
        color: "white",
        padding: "3px", // Reduce padding for a smaller badge
        borderRadius: "50%",
        fontSize: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
    : {
        position: "absolute",
        top: "2px", // Move it down a bit
        right: "-12px", // Bring it closer to the "Promotions" word
        backgroundColor: "gold",
        color: "white",
        padding: "3px", // Reduce padding for a smaller badge
        borderRadius: "50%",
        fontSize: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };

  const badgeIconStyle = {
    fontSize: "12px",
  };

  return (
    <nav className='mainmenu-nav'>
      <ul className='mainmenu'>
        <li className='menu-item-has-children'>
          <Link to='#'>
            Services <FaAngleDown />
          </Link>
          <ServicesDropdown />
        </li>
        <li>
          <Link to={"/projects"}>Projects</Link>
        </li>
        <li>
          <Link to={"/about-us"}>About Us</Link>
        </li>
        <li>
          <Link to={"/contact"}>Contact</Link>
        </li>
        <li style={promotionItemStyle}>
          <Link to={"/promotions"}>Promotions</Link>
          <div style={promotionBadgeStyle}>
            <FaStar style={badgeIconStyle} />
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
