import React from 'react';
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import AboutFour from "../component/about/AboutOne";
import AboutThree from "../component/about/_AboutThree";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BcrumbBannerTwo from "../elements/breadcrumb/BcrumbBannerTwo";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

const AboutUs = () => {
  return (
    <>
      <SEO
        title='Axellier | About Us - Your Trusted Digital Partner'
        description='Discover Axellier, a rapidly growing digital agency delivering innovative solutions across AI, marketing, and web development. We pride ourselves on our client-centered excellence, cutting-edge technology, and commitment to long-term partnerships. Learn more about our team and values.'
        keywords='Axellier digital agency, client-centered excellence, AI solutions, web development, digital marketing, innovation, long-term partnerships, inclusive culture, custom software solutions, trusted digital partner'
      />

      <ColorSwitcher />
      <main className='main-wrapper'>
        <HeaderOne />
        <BcrumbBannerTwo
          title='One of the fastest growing agency'
          paragraph='We at Axellier excel in delivering diverse digital solutions globally. Our dedicated teams ensure a secure, impactful, and innovative online presence for our clients.'
          styleClass='thumbnail-4'
          mainThumb='/images/banner/banner-thumb-3.png'
        />
        <AboutThree />
        <AboutFour />
        {/* <AboutFive /> */}
        {/* <ProcessOne /> */}
        <CtaLayoutOne />
        <FooterOne parentClass='' />
      </main>
    </>
  );
};

export default AboutUs;