import React from 'react';
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

const TermsOfUse = () => {
  return (
    <>
      <SEO title='Terms Of Use' />
      <ColorSwitcher />
      <main className='main-wrapper'>
        <HeaderOne />
        <BreadCrumbOne title='Terms Of Use' page='Terms Of Use' />
        <div className='section-padding privacy-policy-area'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-10'>
                <div className='privacy-policy-content'>
                  <p>
                    At Axellier, we are committed to delivering cutting-edge
                    digital solutions. Our team prioritizes innovation and
                    client satisfaction, offering tailored strategies to meet
                    the unique needs of every business. From web development to
                    AI-powered tools, we aim to drive real impact, ensuring our
                    clients remain competitive in the ever-evolving digital
                    landscape.
                  </p>
                  <h4>Our Commitment to Excellence</h4>
                  <p>
                    We believe that true excellence comes from a dedication to
                    continuous improvement. By staying at the forefront of
                    technology, we empower businesses with tools that not only
                    solve today’s challenges but also prepare them for the
                    future. Our team of experts works closely with clients to
                    develop solutions that are functional, scalable, and
                    user-friendly, always keeping their goals in mind.
                  </p>
                  <h4>About Axellier</h4>
                  <p>
                    Axellier is more than just a digital agency. We are a
                    partner in innovation, helping businesses across industries
                    unlock their full potential. With a focus on AI-powered
                    solutions, custom development, and data-driven marketing
                    strategies, we deliver transformative results. For
                    inquiries, reach out to us at
                    <a href='mailto:hello@axellier.com'> hello@axellier.com</a>.
                  </p>
                  <h4>Why Choose Axellier?</h4>
                  <p>
                    Our proven track record of delivering successful projects,
                    combined with our commitment to client satisfaction, makes
                    us the go-to partner for businesses seeking innovative
                    digital solutions. We take pride in our transparent
                    processes and collaborative culture, ensuring that our
                    clients are always informed and involved every step of the
                    way.
                  </p>
                  <h4>Tailored Solutions for Every Need</h4>
                  <p>
                    At Axellier, we understand that no two businesses are alike.
                    That’s why we offer customized solutions that are
                    specifically designed to meet the individual needs of our
                    clients. From AI and machine learning to custom software and
                    mobile app development, we provide the expertise necessary
                    to drive business growth and success.
                  </p>
                  <h4>Dedicated to Security and Reliability</h4>
                  <p>
                    In a digital world where security is paramount, we go the
                    extra mile to ensure that our solutions are safe and
                    reliable. Through regular security audits, compliance
                    checks, and ongoing support, we ensure that your digital
                    infrastructure is protected against threats. Your peace of
                    mind is our priority.
                  </p>
                  <h4>Innovating for the Future</h4>
                  <p>
                    At Axellier, we don’t just respond to industry trends—we set
                    them. Our team is always pushing the boundaries of what’s
                    possible, leveraging the latest technologies to offer
                    innovative solutions that keep our clients ahead of the
                    competition. As the digital landscape evolves, we remain
                    committed to pioneering the next generation of business
                    solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass='' />
      </main>
    </>
  );
};

export default TermsOfUse;