import React from "react";
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import AboutOne from "../component/about/AboutOne";
import BannerOne from "../component/banner/BannerOne";
import BlogOne from "../component/blog/BlogOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectFour from "../component/project/HomepageProjectFour";
import ServicePropOne from "../component/service/ServicePropOne";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

const DigitalAgency = () => {
  return (
    <>
      <SEO
        title='Axellier | Digital Agency - Software, Marketing & Insights'
        description='Axellier is your go-to digital partner for custom software solutions, cutting-edge AI, digital marketing, and insightful market research. Discover intelligent solutions for web, mobile, and customer experience optimization.'
        keywords='Axellier, digital agency, custom software development, AI-powered solutions, digital marketing, mobile app development, market research, SEO, social media strategy, data-driven insights, cloud computing, ecommerce solutions'
      />

      <ColorSwitcher />
      <main className='main-wrapper'>
        <HeaderOne />
        <BannerOne />
        <div className='section section-padding-2 bg-color-dark'>
          <div className='container'>
            <SectionTitle
              subtitle='Your Digital Canvas Awaits'
              title='Empower Your Vision with Axellier'
              description='From design to software, we offer expertise that transforms visions into digital realities. Join our journey of innovation'
              textAlignment='heading-light-left'
              textColor=''
            />
            <div className='row'>
              <ServicePropOne
                colSize='col-xl-4 col-md-6'
                serviceStyle=''
                itemShow='6'
              />
            </div>
          </div>
          <ul className='list-unstyled shape-group-10'>
            <li className='shape shape-1'>
              <img src={"/images/others/line-9.png"} alt='Circle' />
            </li>
            <li className='shape shape-2'>
              <img src={"/images/others/bubble-42.png"} alt='Circle' />
            </li>
            <li className='shape shape-3'>
              <img src={"/images/others/bubble-43.png"} alt='Circle' />
            </li>
          </ul>
        </div>
        <AboutOne />

        <ProjectFour />

        <TestimonialOne />

        {/* <CounterUpOne /> */}

        <CtaLayoutOne />
        <BlogOne />

        <FooterOne parentClass='' />
      </main>
    </>
  );
};

export default DigitalAgency;
