import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
  {
    id: 1,
    title: "Client-Centered Excellence",
    para: "We don’t just aim to meet expectations; we strive to exceed them. Every project is approached with a client-first mindset, ensuring that we deliver exceptional results tailored to your specific needs. Your success is our priority, and we work tirelessly to ensure your satisfaction at every stage.",
  },
  {
    id: 2,
    title: "Innovative Solutions, Proven Results",
    para: "We are passionate about innovation, constantly pushing the boundaries of technology and creativity. Our solutions are crafted to not only solve today’s problems but to anticipate tomorrow’s challenges. With a blend of cutting-edge tools and industry expertise, we provide solutions that are both practical and forward-thinking.",
  },
  {
    id: 3,
    title: "Accountability and Integrity",
    para: "When you work with us, you can trust that we take full ownership of the projects we undertake. We ask the tough questions, challenge the status quo, and proactively find ways to improve. We are committed to transparency and integrity, ensuring you always know where your project stands.",
  },
  {
    id: 4,
    title: "Continuous Learning and Improvement",
    para: "In an ever-evolving industry, we never settle. Our team thrives on curiosity and a passion for learning. Every success and every failure serves as an opportunity to grow and improve, ensuring that our clients always benefit from the most refined processes and the latest industry insights.",
  },
  {
    id: 5,
    title: "A Team That Takes Pride in Its Work",
    para: "We don’t just deliver projects—we create work that makes us proud. Every task, no matter how big or small, is executed with care and attention to detail. Our commitment to quality means you receive outcomes that reflect excellence and professionalism.",
  },
  {
    id: 6,
    title: "Inclusive and Collaborative Culture",
    para: "We believe that the best ideas come from a diverse and inclusive environment. Our team is made up of individuals from varied backgrounds, each contributing unique perspectives and skills. This collaborative culture fosters innovation and ensures that every project is as well-rounded as it is successful.",
  },
  {
    id: 7,
    title: "Long-Term Partnerships, Not Just Transactions",
    para: "We don’t see our clients as one-time customers; we build long-term relationships based on trust and mutual success. By partnering with us, you gain a team that is invested in your ongoing success, offering support and guidance long after the project is completed.",
  },
  {
    id: 8,
    title: "Tailored, Personalized Solutions",
    para: "Every business is unique, and so are our solutions. We don’t believe in one-size-fits-all approaches. Our team takes the time to deeply understand your business goals, challenges, and vision to craft customized strategies that truly resonate with your brand and audience.",
  },
  {
    id: 9,
    title: "Exceptional Communication and Transparency",
    para: "We believe that clear, open communication is key to successful partnerships. From day one, you’ll be kept in the loop with regular updates, honest timelines, and a transparent workflow. No hidden surprises—just a smooth, collaborative process from start to finish.",
  },
  {
    id: 10,
    title: "Sustainable and Ethical Practices",
    para: "We are committed to being a socially responsible company. From environmentally conscious practices to promoting an inclusive workplace, we ensure that every action we take reflects our values of integrity, sustainability, and ethics. By working with us, you partner with a team that prioritizes doing good while doing great work.",
  },
  {
    id: 11,
    title: "Cutting-Edge Technology",
    para: "We leverage the latest technologies to provide you with solutions that are not only up-to-date but also future-proof. Our tech stack is continuously evolving to ensure your business remains competitive in a rapidly changing digital landscape.",
  },
  {
    id: 12,
    title: "Proven Track Record of Success",
    para: "With a history of delivering successful projects across industries, we bring expertise, reliability, and credibility to every partnership. Our results speak for themselves, and we’re proud of the long list of satisfied clients we’ve helped achieve their business goals.",
  },
];

const AboutThree = () => {
  return (
    <div className='section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20'>
      <div className='container'>
        <SectionTitle
          subtitle='Our Commitment to Excellence'
          title='Why Partner with Us?'
          description='We understand that choosing the right partner is crucial for your success. Here’s why our clients trust us to deliver exceptional results—time after time, with a personal touch and unwavering commitment.'
          textAlignment='heading-left heading-light-left mb--100'
          textColor=''
        />

        <div className='row'>
          {Datas.map((data) => (
            <div className='col-lg-4' key={data.id}>
              <div className='about-quality'>
                <h3 className='sl-number'>{data.id}</h3>
                <h5 className='title'>{data.title}</h5>
                <p>{data.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className='list-unstyled shape-group-10'>
        <li className='shape shape-1'>
          <img src={"/images/others/circle-1.png"} alt='Circle' />
        </li>
        <li className='shape shape-2'>
          <img src={"/images/others/line-3.png"} alt='Circle' />
        </li>
        <li className='shape shape-3'>
          <img src={"/images/others/bubble-5.png"} alt='Circle' />
        </li>
      </ul>
    </div>
  );
};

export default AboutThree;