import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../../utils';

const PropOne = ({ projectStyle, portfolio }) => {
  const isMobile = window.innerWidth <= 820;
  
  return (
    <>
      <div className={`project-grid ${projectStyle}`} style={styles.projectGrid}>
        <div className="thumbnail" style={styles.thumbnail}>
          <Link to="/contact">
            <div
              style={{
                ...styles.placeholder,
                backgroundImage: `url(${portfolio.image})`, // Assuming the image is dynamically set
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></div>
          </Link>
        </div>
        <div className="content" style={styles.content}>
          <span className="subtitle" style={styles.subtitle}>
            {portfolio.excerpt}
          </span>
          <h3 className="title" style={styles.title}>
            <Link to="/contact" style={styles.titleLink}>
              {portfolio.title}
            </Link>
          </h3>
        </div>
      </div>
    </>
  );
};
const styles = {
  projectGrid: {
    marginBottom: '20px',
    borderRadius: '15px', // Increased border radius for a curvier look
    overflow: 'hidden',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
    backgroundColor: '#fff', // Ensure the background remains white for the curvy look
  },
  projectGridHover: {
    transform: 'scale(1.02)',
  },
  thumbnail: {
    position: 'relative',
    overflow: 'hidden',
    borderTopLeftRadius: '15px', // Consistent curve at the top
    borderTopRightRadius: '15px',
  },
  placeholder: {
    width: '100%',
    height: '200px',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px', // Curve at the top for the placeholder
  },
  content: {
    padding: '20px',
    backgroundColor: '#fff',
    borderBottomLeftRadius: '15px', // Matching the roundness at the bottom
    borderBottomRightRadius: '15px',
  },
  subtitle: {
    fontSize: '14px',
    color: '#888',
    marginBottom: '10px',
  },
  title: {
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '0',
  },
  titleLink: {
    color: '#333',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
  },
  titleLinkHover: {
    color: '#007BFF',
  },
};


export default PropOne;
