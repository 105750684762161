import React from 'react';
import "./map.css";
import { ReactComponent as MapSvg } from "./map.svg";

const ContactLocation = () => {
  return <Map />;
};

const Map = () => {
  return (
    <section className='ag-canvas'>
      <MapSvg className='ag-canvas_svg' />
    </section>
  );
};

export default ContactLocation;
