import React from "react";
import { useParams } from "react-router-dom";
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectPropOne from "../component/project/itemProp/ProjectPropOne";
import ProjectData from "../data/project/ProjectData.json";
import ServiceData from "../data/service/ServiceMain.json";
import BcrumbBannerTwo from "../elements/breadcrumb/BcrumbBannerTwo";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import { slugify } from "../utils";

const allServiceData = ServiceData;
const getProjectData = ProjectData;

const ServiceDetails = () => {
  const params = useParams();
  const serviceSlug = params.slug;

  const getServiceData = allServiceData.filter(
    (data) => slugify(data.title) === serviceSlug
  );
  const detailsService = getServiceData[0];

  return (
    <>
      {" "}
      {detailsService.title === "SoftForge" && (
        <SEO
          title='Innovative AI-Powered & Custom Software Solutions - SoftForge'
          description='SoftForge offers innovative AI-powered solutions and custom software development that meet your business needs. From intelligent AI solutions to tailored mobile app and website development, we help you stay ahead in the digital landscape.'
          keywords='SoftForge services, custom software solutions, AI-powered software, mobile app development, website development, digital security audits, AI business solutions'
        />
      )}
      {detailsService.title === "Marketech" && (
        <SEO
          title='Results-Driven Digital Marketing & Social Media Strategies - Marketech'
          description='Marketech specializes in crafting impactful digital marketing campaigns, from social media strategies to SEO optimization and targeted advertisements, driving measurable results for your brand.'
          keywords='Marketech services, digital marketing strategies, social media management, SEO campaigns, targeted advertisements, online marketing solutions'
        />
      )}
      {detailsService.title === "Insightify" && (
        <SEO
          title='Data-Driven Market Research & Customer Journey Optimization - Insightify'
          description='Insightify leverages in-depth market research, sentiment analysis, and customer journey mapping to help your business make informed decisions and optimize conversions.'
          keywords='Insightify services, market research, customer journey analysis, sentiment analysis, conversion rate optimization, data-driven strategies'
        />
      )}
      <ColorSwitcher />
      <main className='main-wrapper'>
        <HeaderOne />
        <BcrumbBannerTwo
          title={detailsService.title}
          paragraph={detailsService.description}
          styleClass=''
          mainThumb={`/images/services/${detailsService.title.toLowerCase()}.jpeg`}
        />
        {/* <AboutTwo /> */}

        <div className='section section-padding bg-color-white pb--70'>
          <SectionTitle
            subtitle={detailsService.process.sectionSub}
            title={detailsService.process.sectionTitle}
            description={detailsService.process.para}
            textAlignment=''
            textColor='mb--90'
          />
          <div className='container'>
            {detailsService.process.steps.map((data, index) => (
              <div key={index}>
                {/* <Tilty perspective={2000}>
                  <div className='thumbnail'>
                    {/* <img src={data.thumb} alt='Thumbnail' /> 
                  </div>
                </Tilty> */}
                <div key={index} style={styles.content}>
                  <span style={styles.subtitle}>{data.subtitle}</span>
                  <h3 style={styles.title}>{data.title}</h3>
                  <p style={styles.paragraph}>{data.paragraph}</p>
                </div>
              </div>
            ))}
          </div>
          <ul className='shape-group-17 list-unstyled'>
            <li className='shape shape-1'>
              <img src={"/images/others/bubble-24.png"} alt='Bubble' />
            </li>
            <li className='shape shape-2'>
              <img src={"/images/others/bubble-23.png"} alt='Bubble' />
            </li>
            <li className='shape shape-3'>
              <img src={"/images/others/line-4.png"} alt='Line' />
            </li>
            <li className='shape shape-4'>
              <img src={"/images/others/line-5.png"} alt='Line' />
            </li>
            <li className='shape shape-5'>
              <img src={"/images/others/line-4.png"} alt='Line' />
            </li>
            <li className='shape shape-6'>
              <img src={"/images/others/line-5.png"} alt='Line' />
            </li>
          </ul>
        </div>
        <div className='section section-padding'>
          <div className='container'>
            <SectionTitle
              subtitle='Our Projects'
              title={`${detailsService.title} Highlights`}
              description=''
              textAlignment=''
              textColor=''
            />
            <div className='row row-35'>
              {getProjectData.slice(0, 2).map((data) => (
                <div className='col-md-6' key={data.id}>
                  <ProjectPropOne projectStyle='' portfolio={data} />
                </div>
              ))}
            </div>
          </div>
          <ul className='shape-group-16 list-unstyled'>
            <li className='shape shape-1'>
              <img src={"/images/others/circle-2.png"} alt='circle' />
            </li>
            <li className='shape shape-3'>
              <img src={"/images/others/bubble-1.png"} alt='Line' />
            </li>
          </ul>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass='' />
      </main>
    </>
  );
};
const styles = {
  content: {
    padding: "20px",
    paddingBottom: "0px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    ":hover": {
      transform: "scale(1.03)",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
    },
  },
  subtitle: {
    fontSize: "16px",
    color: "#888",
    marginBottom: "10px",
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
  },
  paragraph: {
    fontSize: "16px",
    color: "#555",
    lineHeight: "1.6",
    paddingBottom: "20px",
  },
};

export default ServiceDetails;
